import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scssFiles/HomeParagraph.scss";

function HomeParagraph() {
  return (
    <p className="home-page-style">
      I received my Ph.D. from North Carolina State University, where my dissertation
      focused on "Efficient, Flexible and Secure Implementations for
      Lattice-based Cryptography." In my research, I tackled critical challenges
      in the transition to post-quantum cryptography (PQC), addressing efficiency,
      flexibility, and implementation security through hardware and
      architectural-level support. I exploited side-channel
      vulnerabilities, optimized PQC implementation, and
      designed advanced countermeasures on ASIC/FPGA.
      <br />
      <br />
      Currently, I work as a Senior Hardware Security Architect at Microsoft,
      where I continue to drive my research forward by designing and building
      next-generation secure systems for Confidential Computing. My work
      includes developing cryptographic IPs, integrating side-channel attack
      countermeasures, and creating cutting-edge architectures for
      Root-of-Trust that enable secure environments, particularly for cutting edge
      AI servers.
      <br />
      <br />
      Before joining Microsoft, I founded mithrilAI Corp, a startup originating
      from NC State University. At mithrilAI, we pioneered innovation at the
      intersection of AI and secure hardware. We recieved NSF-SBIR funding and 
      developed the world's first silicon-proven AI circuits designed to mitigate
      hardware attacks, including side-channel and fault injection threats,
      ensuring the confidentiality, integrity, and availability of AI models and
      safeguarding critical systems from vulnerabilities.
    </p>
  );
}

export default HomeParagraph;
