import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scssFiles/Breakthroughs.scss";

function Breakthroughs() {
  return (
    <p className="home-page-style font-custom-style">
      I am thrilled to share with you my journey as a Ph.D. graduate from North Carolina State
      University and my current role as a Senior Hardware Security Architect at Microsoft.
      Throughout my academic and industry career, I have dedicated myself to
      discovering and solving unique implementation vulnerabilities.
      <br />
      <br />
      Before joining Microsoft, I co-founded mithrilAI Corp, a startup that pioneered
      silicon-proven AI circuits designed to mitigate hardware attacks such as side-channel
      and fault injection threats. Our innovative technologies ensure the confidentiality,
      integrity, and availability of AI models, safeguarding critical systems from vulnerabilities.
      At mithrilAI, my NSF-SBIR proposal was accepted and granted for $272,773, which
      significantly propelled our research and development efforts.
      <br />
      <br />
      Currently, I contribute to the Caliptra project at Microsoft, where I design
      next-generation secure systems for Confidential Computing. This work includes
      designing cryptographic IPs and creating new architectures for Root-of-Trust,
      particularly for AI applications.
      <br />
      <br />
      One of my notable achievements includes pioneering the first side-channel
      attacks on post-quantum cryptography algorithms. By examining
      vulnerabilities and understanding the intricate details of these
      algorithms, I strive to fortify their implementations against potential
      threats.
      <br />
      <br />
      However, my expertise extends beyond attacking systems; I am equally
      committed to defending them. I have employed methods such as blinding and
      hiding to protect implementations, ensuring their resilience against
      potential attacks. Implementing classical cryptography algorithms securely
      through techniques like masking and shuffling has been a fascinating
      avenue for me. My effort has been rewarded with multiple patents and
      scientific papers.
      <br />
      <br />
      I am also eager to expand my expertise in fault injection attacks.
      These invasive attacks are more challenging to mitigate, and while I have
      identified various implementation vulnerabilities through side-channel
      attacks, I am now focused on exploring fault attacks to uncover deeper
      insights into system vulnerabilities.
    </p>
  );
}

export default Breakthroughs;
